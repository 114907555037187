import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ContactUs from "./contactUs";

function Layout({
  children,
  className,
  bg = "initial",
  nav = true,
  navBg = null,
  footer = true,
  initial = true,
  contactUs = true,
  style = {},
}) {
  const [show, setShow] = useState(false);
  return (
    <div
      style={{
        background: bg,
        position: "relative",
        ...style,
      }}
      className={initial ? "root" : ""}
    >
      {children && (
        <Container as="main">
          <Row>
            <Col lg={11} className={`mx-auto ${className}`}>
              {children}
            </Col>
          </Row>
        </Container>
      )}
      {contactUs && <ContactUs />}
    </div>
  );
}

export default Layout;
