import React from "react";
import Layout from "../components/layout";
import { Container } from "reactstrap";
import thank from "./../images/thanks.svg";
import thankEnglish from "./../images/thankEnglish.svg";
import { Button, FormControl } from "@mui/material";

const ThanksForSend = ({ pageContext: { language } }) => {
  return (
    <Layout contactUs={false} initial={false}>
      <Container className="thanks">
        <img src={language === "pl" ? thank : thankEnglish} />

        <div className="buttonSubmitContainer">
          <FormControl
            sx={{ width: { md: "100%", sx: "100%" } }}
            className="buttonSubmitContainer__FormControl"
          >
            <Button
              onClick={() => (window.location.href = "/")}
              className="addProjectModal__addProject"
              size="large"
              variant="contained"
              type="submit"
            >
              {language === "pl"
                ? "Wróć na stronę główną"
                : "Comeback to main page"}
            </Button>
          </FormControl>
        </div>
      </Container>
    </Layout>
  );
};

export default ThanksForSend;
